/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag Fragment */
// @ts-check
import { Container } from '@bottlebooks/gatsby-theme-base';
import EventBanner from '@bottlebooks/gatsby-theme-event/src/components/Event/EventBanner';
import EventHeader from '@bottlebooks/gatsby-theme-event/src/components/Event/EventHeader';
import EventVideos from '@bottlebooks/gatsby-theme-event/src/components/LandingPage/EventVideos';
import FeaturedProducersSection from '@bottlebooks/gatsby-theme-event/src/components/LandingPage/FeaturedProducersSection';
import FilesSection from '@bottlebooks/gatsby-theme-event/src/components/LandingPage/FilesSection';
import QuickLinks from '@bottlebooks/gatsby-theme-event/src/components/LandingPage/QuickLinks';
import { graphql } from 'gatsby';
import { Fragment } from 'react';
import { jsx } from 'theme-ui';

export default function LandingPageSections({
  event,
  localeConfig,
  videos,
  files,
  shuffledExhibitors,
  shuffledProducers,
  withOverlay,
  locale,
  segments,
}) {
  return (
    <>
      <EventBanner event={event} withOverlay={withOverlay} />

      <Container sx={{ paddingY: [4, 5] }}>
        <EventHeader event={event} />
      </Container>

      <Container>
        <QuickLinks localeConfig={localeConfig} />
      </Container>

      {Boolean(videos?.length) && (
        <Container>
          <EventVideos videos={videos} />
        </Container>
      )}

      {Boolean(files.length) && (
        <Container>
          <FilesSection files={files} />
        </Container>
      )}

      {Boolean(shuffledProducers?.length) && (
        <FeaturedProducersSection
          producers={shuffledProducers}
          locale={locale}
        />
      )}
    </>
  );
}

export const fragment = graphql`
  fragment bb_LandingPageSections on Bottlebooks_Event {
    ...bb_EventBanner
    ...bb_EventHeader
    videos {
      ...bb_EventVideos
    }
    files {
      nodes {
        ...bb_FilesSection
      }
    }
  }

  fragment LandingPageSections on LocaleConfig {
    ...QuickLinks
  }

  fragment LandingPageSections_FeaturedExhibitors on Exhibitor {
    ...FeaturedExhibitorsSection
  }
`;
